$.fn.exists = function(callback) {
  var args = [].slice.call(arguments, 1);
  if (this.length) {
    callback.call(this, args);
  }
  return this;
};
$(function() {

  $("[data-toggle='mobilemenu']").click(function() {
    $("body").toggleClass("toggleMobileMenu");
    return false;
  });

  $($('#heroslider')).exists(function() {
    var owl = $('#heroslider').owlCarousel({
        loop:true,
        margin:10,
        responsiveClass:true,
        dots:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:false
            },
            1000:{
                items:1,
                nav:false,
                loop:false
            }
        }
    });
    $(".next").click(function() {
      owl.trigger('next.owl.carousel');
    });
    $(".prev").click(function() {
      owl.trigger('prev.owl.carousel');
    });
  });
  $($('#newsslider')).exists(function() {
    var owl = $('#newsslider').owlCarousel({
        loop:true,
        margin:10,
        responsiveClass:true,
        dots:true,
        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:false
            },
            1000:{
                items:3,
                nav:false,
                loop:false
            }
        }
    });
  });

});
